<template>
  <div>
    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
    <b-button
      size="sm"
      variant="outline-primary"
      block
      style="display: none"
      @click="appendHashCodes"
    >
      {{ 'Append Hash Codes' }}
    </b-button>
    <b-alert
      v-if="error"
      class="p-1"
      variant="danger"
      show
    >
      {{ error }}
    </b-alert>
    <b-row
      v-if="uploadPending"
      class="mb-2"
    >
      <b-col
        cols="12"
        md="12"
      >
        <b-progress
          :value="uploading"
          :max="100"
          show-progress
          animated
          class="mb-2"
          variant="success"
        />
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <!-- <b-button
          size="sm"
          variant="outline-primary"
          block
          @click="cancelUpload"
        >
          {{ $t('Cancel Upload') }}
        </b-button> -->
      </b-col>
    </b-row>

    <b-button
      v-if="entity.originalFilename"
      variant="secondary"
      @click="upload"
    >
      {{ $t('Upload') }}
    </b-button>
    <b-input-group v-else>
      <b-form-file
        v-model="video.file"
        :placeholder="$t('Choose a file or drop it here...')"
        :drop-placeholder="$t('Drop file here...')"
        accept="video/*"
        @input="upload"
      />
    </b-input-group>
  </div>
</template>

<script>
import axios from '@/libs/axios'
import { Vimeo } from 'vimeo'

export default {
  props: {
    module: {
      type: String,
      required: true,
    },
    parentEntity: {
      type: Object,
      default: null,
      required: false,
    },
    entity: {
      type: Object,
      default: null,
      required: false,
    },
    callback: {
      type: Function,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      vim: null,
      error: '',
      uploadPending: false,
      uploading: 0,
      video: {
        id: null,
        title: '',
        description: '',
        uri: null,
        file: null,
      },
    }
  },
  computed: {
    clientID() {
      return process.env.VUE_APP_VIMEO_CLIENT_ID
    },

    clientSecret() {
      return process.env.VUE_APP_VIMEO_CLIENT_SECRET
    },

    accessToken() {
      return process.env.VUE_APP_VIMEO_ACCESS_TOKEN
    },
  },

  mounted() {
    this.vim = new Vimeo(this.clientID, this.clientSecret, this.accessToken)
  },
  methods: {
    cancelUpload() {
      this.uploading = 0
    },
    upload() {
      this.uploadPending = true
      // eslint-disable-next-line prefer-template
      const videoName = this.entity.title
        ? this.entity.ordering + '. ' + this.entity.title
        : this.video.file.name
      const privacyProp = {
        add: false,
        comments: 'nobody',
        download: false,
        embed: 'public',
        // embed: this.entity.visibility === 1 ? 'public' : 'whitelist',
        view:
          // eslint-disable-next-line no-nested-ternary
          this.entity.visibility === 1
            ? 'anybody'
            : this.entity.visibility === 2
              ? 'unlisted'
              : 'disable',
      }

      if (this.entity.originalFilename) {
        return this.vim.request(
          {
            method: 'POST',
            path: '/me/videos',
            query: {
              name: videoName,
              upload: { approach: 'pull', link: this.entity.originalFilename },
              privacy: privacyProp,
            },
          },
          (error, body) => {
            this.uploadEnded()
            if (error) {
              console.log(error)
            } else {
              // eslint-disable-next-line vue/no-mutating-props
              this.entity.vimeoId = this.parseVimeoId(body.uri)
              if (this.entity.visibility === 1) {
                this.saveVimeoId()
              } else {
                this.getVimeoHash(this.saveVimeoId())
              }
            }
          }
        )
      }

      return this.tusVimeoUpload(
        this.video.file,
        {
          // https://github.com/vimeo/vimeo.js/#uploading-videos
          // video params
          name: videoName,
          privacy: privacyProp,
        },
        (uri) => {
          console.log('File upload completed. Your Vimeo URI is:', uri)
          this.uploadEnded()
          this.video.uri = uri
          // eslint-disable-next-line vue/no-mutating-props
          this.entity.vimeoId = this.parseVimeoId(uri)
          this.getVimeoHash(false)
        },
        (bytesUploaded, bytesTotal) => {
          // progress callback
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          this.uploading = percentage
        },
        (error) => {
          console.log(`Failed because: ${error}`)
          this.uploading = 0
        }
      )
    },
    tusVimeoUpload(
      path,
      params,
      completeCallback,
      progressCallback,
      errorCallback
    ) {
      if (this.entity.vimeoId) {
        // eslint-disable-next-line prefer-template
        return this.vim.replace(
          path,
          '/videos/' + this.entity.vimeoId,
          completeCallback,
          progressCallback,
          errorCallback
        )
      }
      return this.vim.upload(
        path,
        params,
        completeCallback,
        progressCallback,
        errorCallback
      )
    },
    saveVimeoId() {
      axios
        .put(`${this.module}/vimeoid`, {
          id: this.entity.id,
          vimeoId: this.entity.vimeoId,
          vimeoHash: this.entity.vimeoHash,
        })
        .then((response) => {
          if (response.data) {
            this.callback()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    parseVimeoId(uri) {
      return uri.replace('/videos/', '')
    },
    parseVimeoHash(uri) {
      // eslint-disable-next-line prefer-template
      return uri.replace('https://vimeo.com/' + this.entity.vimeoId + '/', '')
    },
    getVimeoHash(callback) {
      this.vim.request(
        {
          method: 'GET',
          // eslint-disable-next-line prefer-template
          path: '/videos/' + this.entity.vimeoId,
        },
        (error, body) => {
          if (error) {
            console.log(error)
          } else {
            // eslint-disable-next-line vue/no-mutating-props
            this.entity.vimeoHash = this.parseVimeoHash(body.link)
            if (callback) {
              callback()
            }
          }
        }
      )
    },
    appendHashCodes() {
      axios
        .get(
          'videos/search?uploaded=true&size=1000&congress=' +
          this.entity.congress.id
        )
        .then((response) => {
          const videos = response.data.content
          videos.forEach((video) => {
            this.vim.request(
              {
                method: 'GET',
                // eslint-disable-next-line prefer-template
                path: '/videos/' + video.vimeoId,
              },
              (error, body) => {
                if (error) {
                  console.log(error)
                } else if (video.unlisted && !video.vimeoHash) {
                  const item = video
                  // eslint-disable-next-line prefer-template
                  item.vimeoHash = body.link.replace(
                    'https://vimeo.com/' + video.vimeoId + '/',
                    ''
                  )
                  axios
                    .put(`${this.module}/vimeoid`, {
                      id: item.id,
                      vimeoId: item.vimeoId,
                      vimeoHash: item.vimeoHash,
                    })
                    .then((response2) => {
                      console.log(response2.data)
                    })
                    .catch((error2) => {
                      console.log(error2)
                    })
                }
              }
            )
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    uploadEnded() {
      this.uploading = 0
      this.uploadPending = false
    },
  },
}
</script>
